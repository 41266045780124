/**
 * Application entry point
 */
window.$ = window.jQuery = require('jquery');

// require('../node_modules/waypoints/lib/jquery.waypoints.js');
require('waypoints/lib/jquery.waypoints.js');
const loadGoogleMapsApi = require('load-google-maps-api');

// Load application styles
import 'styles/index.scss';
import 'fullpage.js/dist/fullpage.css';
import 'fullpage.js/vendors/scrolloverflow.min.js';
import fullpage from 'fullpage.js/dist/fullpage.js';

import daydreamDan from '../views/infowindows/daydream-dan.html';
const daydreamDanCoords = { lng: 147.3334352, lat: -42.8865441 };
import daydreametaoin from '../views/infowindows/daydream-etaoin.html';
const daydreametaoinCoords = { lng: 146.3451775, lat: -41.1770585 };
import daydreamgavin from '../views/infowindows/daydream-gavin.html';
const daydreamgavinCoords = { lng: 146.6784639, lat: -43.0899388 };
import daydreamheleen from '../views/infowindows/daydream-heleen.html';
const daydreamheleenCoords = { lng: 148.2928957, lat: -41.2491264 };
import daydreamlaura from '../views/infowindows/daydream-laura.html';
const daydreamlauraCoords = { lng: 148.2824966, lat: -41.16936 };
import daydreamlee from '../views/infowindows/daydream-lee.html';
const daydreamleeCoords = { lng: 147.1440875, lat: -41.4332215 };
import daydreamlewis from '../views/infowindows/daydream-lewis.html';
const daydreamlewisCoords = { lng: 147.2484553, lat: -42.7341163 };
import daydreamnick from '../views/infowindows/daydream-nick.html';
const daydreamnickCoords = { lng: 146.9725769, lat: -43.1435829 };
import daydreampete from '../views/infowindows/daydream-pete.html';
const daydreampeteCoords = { lng: 147.3251668, lat: -42.8813467 };
import daydreampriyanka from '../views/infowindows/daydream-priyanka.html';
const daydreampriyankaCoords = { lng: 146.7147566, lat: -43.139689 };
import daydreamsiwka from '../views/infowindows/daydream-siwka.html';
const daydreamsiwkaCoords = { lng: 147.460602, lat: -40.9976227 };

import factDan from '../views/infowindows/fact-dan.html';
const factDanCoords = { lng: 147.216667, lat: -42.616667 };
import factdom from '../views/infowindows/fact-dom.html';
const factdomCoords = { lng: 147.9243335, lat: -43.0165369 };
import factedita from '../views/infowindows/fact-edita.html';
const facteditaCoords = { lng: 147.3856342, lat: -43.2041137 };
import factheleen from '../views/infowindows/fact-heleen.html';
const factheleenCoords = { lng: 146.793765, lat: -42.1223022 };
import factian from '../views/infowindows/fact-ian.html';
const factianCoords = { lng: 147.3216248, lat: -42.8825187 };
import factken from '../views/infowindows/fact-ken.html';
const factkenCoords = { lng: 147.500776, lat: -42.821673 };
import factlaura from '../views/infowindows/fact-laura.html';
const factlauraCoords = { lng: 148.2835127, lat: -41.1626051 };
import factpete from '../views/infowindows/fact-pete.html';
const factpeteCoords = { lng: 147.2319766, lat: -43.1822876 };
import factqiao from '../views/infowindows/fact-qiao.html';
const factqiaoCoords = { lng: 147.2613323, lat: -42.8127528 };
import factsean from '../views/infowindows/fact-sean.html';
const factseanCoords = { lng: 148.1700486, lat: -42.0853563 };
import factsiwka from '../views/infowindows/fact-siwka.html';
const factsiwkaCoords = { lng: 147.1183324, lat: -41.446371 };

import secretGareth from '../views/infowindows/secret-gareth.html';
const secretGarethCoords = { lng: 148.058482, lat: -42.1344145 };
import secretgavin from '../views/infowindows/secret-gavin.html';
const secretgavinCoords = { lng: 147.3318768, lat: -42.8898828 };
import secretheleen from '../views/infowindows/secret-heleen.html';
const secretheleenCoords = { lng: 145.9502647, lat: -41.6514175 };
import secretian from '../views/infowindows/secret-ian.html';
const secretianCoords = { lng: 147.2373191, lat: -42.895994 };
import secretken from '../views/infowindows/secret-ken.html';
const secretkenCoords = { lng: 146.591489, lat: -41.559026 };
import secretlaura from '../views/infowindows/secret-laura.html';
const secretlauraCoords = { lng: 148.3576453, lat: -41.2751842 };
import secretlee from '../views/infowindows/secret-lee.html';
const secretleeCoords = { lng: 147.3247355, lat: -42.8830736 };
import secretlewis from '../views/infowindows/secret-lewis.html';
const secretlewisCoords = { lng: 148.3107552, lat: -41.8732714 };
import secretpete from '../views/infowindows/secret-pete.html';
const secretpeteCoords = { lng: 147.4051848, lat: -43.2426305 };
import secretsean from '../views/infowindows/secret-sean.html';
const secretseanCoords = { lng: 145.2748762, lat: -42.0660772 };
import secretsiwka from '../views/infowindows/secret-siwka.html';
const secretsiwkaCoords = { lng: 147.8369459, lat: -42.8231125 };

import singleDan from '../views/infowindows/single-dan.html';
const singleDanCoords = { lng: 147.9843718, lat: -42.4300505 };
import singledom from '../views/infowindows/single-dom.html';
const singledomCoords = { lng: 147.3271949, lat: -42.8821377 };
import singleemerald from '../views/infowindows/single-emerald.html';
const singleemeraldCoords = { lng: 147.859327, lat: -42.7962797 };
import singlegareth from '../views/infowindows/single-gareth.html';
const singlegarethCoords = { lng: 147.2616955, lat: -42.810856 };
import singlegavin from '../views/infowindows/single-gavin.html';
const singlegavinCoords = { lng: 147.3334136, lat: -42.8896827 };
import singlelaura from '../views/infowindows/single-laura.html';
const singlelauraCoords = { lng: 148.2843787, lat: -41.1679185 };
import singlelee from '../views/infowindows/single-lee.html';
const singleleeCoords = { lng: 147.3268167, lat: -42.8830434 };
import singlelewis from '../views/infowindows/single-lewis.html';
const singlelewisCoords = { lng: 145.9391655, lat: -41.6803672 };
import singlepete from '../views/infowindows/single-pete.html';
const singlepeteCoords = { lng: 147.3461906, lat: -43.3627547 };
import singlepriyanka from '../views/infowindows/single-priyanka.html';
const singlepriyankaCoords = { lng: 147.492822, lat: -42.026489 };
import singlesean from '../views/infowindows/single-sean.html';
const singleseanCoords = { lng: 148.3, lat: -42.15 };
import singlesiwka from '../views/infowindows/single-siwka.html';
const singlesiwkaCoords = { lng: 147.9232849, lat: -42.6620723 };

import tipAmbleside from '../views/infowindows/tip-ambleside.html';
const tipAmblesideCoords = { lng: 146.3718344, lat: -41.2046719 };
import tipbarrington from '../views/infowindows/tip-barrington.html';
const tipbarringtonCoords = { lng: 146.2804355, lat: -41.3315868 };
import tipcentral from '../views/infowindows/tip-central.html';
const tipcentralCoords = { lng: 146.5894198, lat: -41.8448002 };
import tipdove from '../views/infowindows/tip-dove.html';
const tipdoveCoords = { lng: 145.9585204, lat: -41.6613174 };
import tipemporium from '../views/infowindows/tip-emporium.html';
const tipemporiumCoords = { lng: 146.3233942, lat: -41.381823 };
import tipkentish from '../views/infowindows/tip-kentish.html';
const tipkentishCoords = { lng: 146.1496452, lat: -41.4761738 };
import tipLakeBarrington from '../views/infowindows/tip-lake-barrington.html';
const tipLakeBarringtonCoords = { lng: 146.22, lat: -41.37 };
import tipmarion from '../views/infowindows/tip-marion.html';
const tipmarionCoords = { lng: 147.8704396, lat: -42.8213948 };
import tiproland from '../views/infowindows/tip-roland.html';
const tiprolandCoords = { lng: 146.2493915, lat: -41.4893931 };

import iconArts from '../assets/images/icons-arts.png';
import iconDrinks from '../assets/images/icons-drinks.png';
import iconFood from '../assets/images/icons-food.png';
import iconNature from '../assets/images/icons-nature.png';
import iconSecret from '../assets/images/icons-secret.png';
import iconTips from '../assets/images/icons-tip.png';

// ================================
// START YOUR APP HERE
// ================================

// Parallax Effect
(function($) {

    var delay = 500; //milliseconds
    var timeoutId;
    var animationIsFinished = false;
    const hills = $(".bg.hills");

    var myFullpage = new fullpage('#fullpage', {
        sectionSelector: '.fullsection',
        licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
        scrollOverflow: true,
        onLeave: function(origin, destination, direction){
            // console.log('origin: ', origin);
            // console.log('destination: ', destination);
            // console.log('direction: ', direction);
            if($(origin.item).hasClass('hero-container') || $(destination.item).hasClass('hero-container')) {
                var curTime = new Date().getTime();

                //animating my element
                if (direction === 'down') {
                    $('.hero-foot a').fadeOut();
                    hills.addClass('stretch');
                } else {
                    $('.hero-foot a').fadeIn();
                    hills.removeClass('stretch');
                }

                clearTimeout(timeoutId);

                timeoutId = setTimeout(function(){
                    animationIsFinished = true;

                    fullpage_api.moveTo(destination.index + 1);
                }, delay);

                return animationIsFinished;
            }
            // if ($(destination.item).hasClass('social')) {
            //     myFullpage['fixedElements'] = '.social-images';
            //     console.log(myFullpage);
            // };
        },
        afterRender: function(){
            // const floatSocialImages = $('.social-images');
            // const scrollableSocial = $('.social .fp-scrollable');
            // scrollableSocial.append(floatSocialImages.get(0));
            // floatSocialImages.remove();
        }
    });

    $('.hero-foot a').on('click', function(e) {
        e.preventDefault();
        fullpage_api.moveTo(2);
    });

    $('.scroll-down a').on('click', function(e) {
        e.preventDefault();
        fullpage_api.moveTo(3);
    });

    $('.map-container a').on('click', function(e) {
        e.preventDefault();
        $('.map-overlay').fadeOut();
    });

    $('.loader').animate({
        opacity: 0
    }, 500, function() {
        $(this).hide();
    });

    var waypoints = $('.map').waypoint({
        handler: function(direction) {
            if(direction === 'down') {
                $(".hero-container .bg").addClass('stuck');
            } else {
                $(".hero-container .bg").removeClass('stuck');
                $('html, body').animate({
                    scrollTop: 0
                }, 1000)
                $('.hills').delay(500).removeClass('stretch');
            }
            // console.log('direction: ',  direction);
            // console.log('this.element: ',  this.element);
        },
        offset: '100%'
    })

    var waypoints2 = $('.social').waypoint({
        handler: function(direction) {
            if(direction === 'down') {
                $(".social .bg").removeClass('stuck');
            } else {
                $(".social .bg").addClass('stuck');
            }
        }
    })

    // Google Maps
    var map;
    var mapStyle = [
      {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#FFCDFF"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#000000"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.neighborhood",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "administrative.neighborhood",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "landscape.man_made",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "landscape.natural",
        "stylers": [
          {
            "color": "#9ba9b0"
          }
        ]
      },
      {
        "featureType": "landscape.natural",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "landscape.natural",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#000000"
          }
        ]
      },
      {
        "featureType": "poi",
        "stylers": [
          {
            "color": "#5b9192"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.local",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "transit",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#0304BB"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      }
    ];

    loadGoogleMapsApi({key:'AIzaSyANvyeF99eiCcDf6tdhW3JPHz2cFb_qVOY'}).then(function(googleMaps) {

        map = new googleMaps.Map(document.getElementById('map'), {
            center: {lat: -42.134138, lng: 146.606548},
            zoom: 7,
            // disableDefaultUI: true,
            scrollwheel: false,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            styles: mapStyle
        });

        var openedWindow = null;
        // var infoWindows = [];
        // function closeWindows() {
        //     for (var infoWindow in infoWindows) {
        //         window[infoWindow].close();
        //     }
        // }

        // Bounds for Tasmania
        var strictBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(-43.793175, 143.300548),
          new google.maps.LatLng(-39.442745, 148.942517)
        );
        // Listen for the dragend event
        google.maps.event.addListener(map, 'dragend', function() {
          if (strictBounds.contains(map.getCenter())) return;

          // We're out of bounds - Move the map back within the bounds

          var c = map.getCenter(),
          x = c.lng(),
          y = c.lat(),
          maxX = strictBounds.getNorthEast().lng(),
          maxY = strictBounds.getNorthEast().lat(),
          minX = strictBounds.getSouthWest().lng(),
          minY = strictBounds.getSouthWest().lat();

          if (x < minX) x = minX;
          if (x > maxX) x = maxX;
          if (y < minY) y = minY;
          if (y > maxY) y = maxY;

          map.setCenter(new google.maps.LatLng(y, x));
        });

        function addMarker(variable, position, icon, content) {
            var infoWindow = variable + 'Window';
            window[variable] = new googleMaps.Marker({
                position: position,
                map: map,
                icon: icon
            })
            window[infoWindow] = new googleMaps.InfoWindow({
                content: content,
                maxWidth: 360
            });
            // infoWindows.push(infoWindow);
            window[variable].addListener('click', function() {
                // closeWindows();
                if (openedWindow) {
                  window[openedWindow].close();
                }
                // console.log(variable + ' marker clicked');
                window[infoWindow].open(map, window[variable]);
                openedWindow = infoWindow;
            })
        }

        addMarker('daydream_dan', daydreamDanCoords, iconFood, daydreamDan);
        addMarker('daydream_etaoin', daydreametaoinCoords, iconNature, daydreametaoin);
        addMarker('daydream_gavin', daydreamgavinCoords, iconNature, daydreamgavin);
        addMarker('daydream_heleen', daydreamheleenCoords, iconNature, daydreamheleen);
        addMarker('daydream_laura', daydreamlauraCoords, iconNature, daydreamlaura);
        addMarker('daydream_lee', daydreamleeCoords, iconNature, daydreamlee);
        addMarker('daydream_lewis', daydreamlewisCoords, iconNature, daydreamlewis);
        addMarker('daydream_nick', daydreamnickCoords, iconNature, daydreamnick);
        addMarker('daydream_pete', daydreampeteCoords, iconFood, daydreampete);
        addMarker('daydream_priyanka', daydreampriyankaCoords, iconNature, daydreampriyanka);
        addMarker('daydream_siwka', daydreamDanCoords, iconNature, daydreamDan);

        addMarker('fact_dan', factDanCoords, iconArts, factDan);
        addMarker('fact_dom', factdomCoords, iconNature, factdom);
        addMarker('fact_edita', facteditaCoords, iconFood, factedita);
        addMarker('fact_heleen', factheleenCoords, iconArts, factheleen);
        addMarker('fact_ian', factianCoords, iconFood, factian);
        addMarker('fact_ken', factkenCoords, iconFood, factken);
        addMarker('fact_laura', factlauraCoords, iconNature, factlaura);
        addMarker('fact_pete', factpeteCoords, iconDrinks, factpete);
        addMarker('fact_qiao', factqiaoCoords, iconArts, factqiao);
        addMarker('fact_sean', factseanCoords, iconFood, factsean);
        addMarker('fact_siwka', factsiwkaCoords, iconNature, factsiwka);

        addMarker('secret_gareth', secretGarethCoords, iconSecret, secretGareth);
        addMarker('secret_gavin', secretgavinCoords, iconSecret, secretgavin);
        addMarker('secret_heleen', secretheleenCoords, iconSecret, secretheleen);
        addMarker('secret_ian', secretianCoords, iconSecret, secretian);
        addMarker('secret_ken', secretkenCoords, iconSecret, secretken);
        addMarker('secret_laura', secretlauraCoords, iconSecret, secretlaura);
        addMarker('secret_lee', secretleeCoords, iconSecret, secretlee);
        addMarker('secret_lewis', secretlewisCoords, iconSecret, secretlewis);
        addMarker('secret_pete', secretpeteCoords, iconSecret, secretpete);
        addMarker('secret_sean', secretseanCoords, iconSecret, secretsean);
        addMarker('secret_siwka', secretsiwkaCoords, iconSecret, secretsiwka);

        addMarker('single_dan', singleDanCoords, iconNature, singleDan);
        addMarker('single_dom', singledomCoords, iconArts, singledom);
        addMarker('single_emerald', singleemeraldCoords, iconNature, singleemerald);
        addMarker('single_gareth', singlegarethCoords, iconArts, singlegareth);
        addMarker('single_gavin', singlegavinCoords, iconNature, singlegavin);
        addMarker('single_laura', singlelauraCoords, iconNature, singlelaura);
        addMarker('single_lee', singleleeCoords, iconArts, singlelee);
        addMarker('single_lewis', singlelewisCoords, iconFood, singlelewis);
        addMarker('single_priyanka', singlepriyankaCoords, iconArts, singlepriyanka);
        addMarker('single_pete', singlepeteCoords, iconNature, singlepete);
        addMarker('single_sean', singleseanCoords, iconNature, singlesean);
        addMarker('single_siwka', singlesiwkaCoords, iconNature, singlesiwka);

        addMarker('tip_ambleside', tipAmblesideCoords, iconTips, tipAmbleside);
        addMarker('tip_barrington', tipbarringtonCoords, iconTips, tipbarrington);
        addMarker('tip_central', tipcentralCoords, iconTips, tipcentral);
        addMarker('tip_dove', tipdoveCoords, iconTips, tipdove);
        addMarker('tip_emporium', tipemporiumCoords, iconTips, tipemporium);
        addMarker('tip_kentish', tipkentishCoords, iconTips, tipkentish);
        addMarker('tip_lake-barrington', tipLakeBarringtonCoords, iconTips, tipLakeBarrington);
        addMarker('tip_marion', tipmarionCoords, iconTips, tipmarion);
        addMarker('tip_roland', tiprolandCoords, iconTips, tiproland);

    }).catch(function(error) {
        console.log('error: ', error);
    });

})(jQuery);
